.modal-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  pointer-events: none;
  transition: 0.7s;
}
.modal-active-container {
  opacity: 1;
  pointer-events: all;
  z-index: 2000;
}

.content-container {
  max-height: 50vh;
  max-width: 70vh;
  transform: scale(0.5);
  transition: 0.7s all;
}
.content-container > img {
  height: auto;
  width: 95%;
  max-width: 50vh;
  padding: 0 1em 0 1em;
}
.content-active-container {
  transform: scale(1);
}

.btn {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
  margin: 20px auto;
  cursor: pointer;
}
.btn:before {
  content: "+";
  color: red;
  position: absolute;
  z-index: 2;
  transform: rotate(45deg);
  font-size: 50px;
  line-height: 1;
  top: -7px;
  left: 5px;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}
.btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: red;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
  transform: scale(0.01);
}
.btn:hover:after {
  transform: scale(1);
}
.btn:hover:before {
  transform: scale(0.8) rotate(45deg);
  color: #fff;
}
