.index_container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, 1fr);
  font-family: "Open Sans";
}

/* --------------first_level------------------------ */
.first_level_container {
  grid-column: 1/2;
  grid-row: 1/2;
  min-height: 90vh;
  background-image: url("../../data/photo/first_level.png");
  background-size: cover;
  background-position: right center;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.greeting_container {
  font-family: "Marck Script";
  font-size: 2em;
  font-style: italic;
  color: white;
  margin: 1em;
  margin-left: 2em;
}
.button_container {
  position: relative;
  height: max-content;
  margin: 0 auto;
  cursor: pointer;
}

.arrow_8 {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0px auto 45px auto;
}
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}
.arrow_8:before,
.arrow_8:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: arrow-8 3s linear infinite;
}
.arrow_8:after {
  animation: arrow-8 3s linear infinite -1.5s;
}
@keyframes arrow-8 {
  0% {
    opacity: 0;
    transform: translate(0, -40px) rotate(-45deg);
  }
  10%,
  90% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0, 0) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: translate(0, 40px) rotate(-45deg);
  }
}

/* --------------second_level----------------------- */
.second_level_container {
  grid-column: 1/2;
  grid-row: 2/3;
  height: 85vh;
}
.title_second_level_container {
  text-align: center;
  margin-top: 1em;
}
.body_second_level_container {
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-template-rows: 1fr;
  height: 90%;
}
/*-----------_slider------------------------------- */
.left_container {
  grid-column: 1/2;
  grid-row: 1/2;
  margin: 1em;
  background: linear-gradient(90deg, #49cddf 10%, #166094 100%);
  padding: 0.2em;
  border-radius: 0 50px 0 50px;
  box-shadow: 7px 7px 10px 0px rgb(0 0 0 / 80%);
}
.left_border_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90% 10%;
  justify-items: center;
  background-color: white;
  height: 100%;
  border-radius: 0 47px 0 47px;
}

.body_slider_container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  grid-row: 1/2;
  grid-column: 1/2;
}
.slider_container {
  grid-column: 1/2;
  grid-row: 1/2;
  background-size: cover;
  border-radius: 5px 5px 5px 5px;
  margin: 0.2em;
  position: relative;
  transition: background-image 0.6s ease-in-out;
}
.fade_in_container {
  margin: 0.2em;
}

/* ----------------------------- */
.fade_in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}
.fade_out {
  opacity: 1;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ------------------------------- */
.title_number_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: white;
}
.title_slider_container {
  font-size: 2em;
}
.title_slider_container,
.number_slider_container {
  padding: 0.5em;
  text-shadow: 0px 0px 2px black;
}

.text_container {
  display: flex;
  grid-column: 2/3;
  grid-row: 1/2;
  padding: 1em;
  border-radius: 0 47px 0 0;
  font-size: 1.6em;
  align-items: center;
  text-indent: 1em;
  position: relative;
}

.dot_container {
  grid-column: 1/2;
  grid-row: 2/3;
  margin: 1em;
}
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #999999;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot:hover {
  background-color: #111111;
}
.active {
  background-color: #111111;
  /* cursor: pointer; */
  /* height: 15px; */
  /* width: 15px; */
  /* margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; */
}
/* ----------next--prev------------------------ */
.prev,
.next {
  cursor: pointer;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #49cddf;
  font-weight: bold;
  font-size: 32px;
  text-shadow: 0px 0px 8px white;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  position: absolute;
  z-index: 100;
}

.next {
  border-radius: 3px 0 0 3px;
  right: 0;
}
@media (pointer: fine) {
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
/* ------------info------------------------------- */
.right_container {
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 1em;
  padding: 0.2em;
  padding: 1em;
  font-size: 1.3em;
}
.right_container > h4 {
  text-align: center;
}
.right_container > p {
  text-indent: 2em;
}
/* ------------media-first_level--------------------- */
@media (max-width: 1150px) {
  .greeting_container {
    font-size: 1.5em;
    margin-left: 1.5em;
  }
}
@media (max-width: 1050px) {
  .greeting_container {
    font-size: 1.4em;
    margin-left: 1.4em;
  }
}
@media (max-width: 1000px) {
  .greeting_container {
    font-size: 1.3em;
    margin-left: 1.3em;
  }

  .arrow_8 {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0px auto 45px auto;
  }
}
@media (max-width: 900px) {
  .greeting_container {
    font-size: 1.2em;
    margin-left: 1.2em;
  }

  .arrow_8 {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 0px auto 35px auto;
  }
  .first_level_container {
    background-image: url("../../data/photo/first_level_mini.png");
  }
}
@media (max-width: 870px) {
  .arrow_8 {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0px auto 30px auto;
  }
  .first_level_container {
    background-position: 10%;
  }
}
@media (max-width: 800px) {
  .greeting_container {
    font-size: 1.1em;
    margin-left: 1.1em;
  }
  .arrow_8 {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0px auto 25px auto;
  }
  .first_level_container {
    background-position: 30%;
  }
}
@media (max-width: 680px) {
  .first_level_container {
    background-position: 60%;
  }
}
@media (max-width: 600px) {
  .arrow_8 {
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0px auto 25px auto;
  }
}
@media (max-width: 500px) {
  .first_level_container {
    background-position: 45%;
  }
  .button_container {
    display: none;
  }
}
@media (max-width: 450px) {
  .first_level_container {
    background-position: 50%;
  }
}

/* ------------media-second_level--------------------- */
@media (max-width: 1525px) {
  .right_container {
    font-size: 1.2em;
  }
}
@media (max-width: 1370px) {
  .right_container {
    font-size: 1.1em;
  }
}
@media (max-width: 1170px) {
  .left_container,
  .right_container {
    margin: 0.5em;
  }
  .text_container {
    padding: 0.5em;
    font-size: 1.5em;
  }
  .title_slider_container {
    font-size: 1.5em;
  }
}

@media (max-width: 1100px) {
  .body_second_level_container {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
  .left_container {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .right_container {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0;
    height: min-content;
  }
  .right_container > h4 {
    display: none;
  }
  .title_slider_container {
    font-size: 1.3em;
  }
  .text_container {
    font-size: 1.2em;
  }
  .dot_container {
    margin: 0.5em;
  }
}
@media (max-width: 900px) {
  .text_container {
    font-size: 1em;
  }
  .title_slider_container {
    font-size: 1.1em;
  }
  .dot_container{
    margin: 0.1em;
  }
  .dot{
    height: 12px;
    width: 12px;
  }
}
@media (max-width: 450px) {
  .text_container {
    font-size: 0.9em;
  }
}
@media (max-width: 430px) {
  .left_container {
    margin: 0em;
    border-radius: 0;
    box-shadow: none;
  }
  .left_border_container {
    grid-template-rows: 1fr;
  }
  .body_slider_container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .slider_container {
    border-radius: 0 47px 0 47px;
    margin: 0;
  }
  .right_container {
    font-size: 0.8em;
  }
  .title_second_level_container {
    font-size: 0.8em;
  }
  .text_container {
    display: flex;
    grid-column: 1/2;
    grid-row: 1/2;
    align-items: flex-end;
    font-size: 0.8em;
    color: white;
  }
  .title_number_container {
    align-items: flex-end;
  }
  .title_slider_container {
    margin: 0 auto;
  }
  .text_container > p {
    margin-bottom: 1em;
  }
  .dot_container {
    display: none;
  }
  .fade_in_container {
    margin: 0.2em 0.2em 1em 0.2em;
  }
  .fade_in {
    text-shadow: 0px 0px 5px black;
  }
}
@media (max-height: 600px) {
  .first_level_container,
  .second_level_container {
    height: 600px;
  }
}
