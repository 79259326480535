.price_container {
  height: 85vh;
  margin: 0.8em 1em 1em 1em;
  padding: 0.2em;
  background: linear-gradient(90deg, #49cddf 10%, #166094 100%);
  border-radius: 0 50px 0 50px;
  font-size: 1.45em;
  font-family: "Open Sans";
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.8);
}
.price_border_container {
  height: -webkit-fill-available;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: white;
  border-radius: 0 47px 0 47px;
  align-items: center;
  justify-items: center;
}
.price_border_container p {
  text-indent: 2em;
  font-style: italic;
}
.osteo_container,
.massage_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

table {
  border-collapse: collapse;
  border: 1px solid #166094;
  width: 100%;
}

th {
  text-align: center;
  padding: 0.5em;
  border: 0.08em solid #166094;
}

td {
  padding: 0.5em;
  border: 0.08em solid #166094;
}
@media (max-width: 1367px) {
  .price_container {
    height: 100%;
  }
}
@media (max-width: 960px) {
  .price_container {
    font-size: 1.4em;
  }
}
@media (max-width: 880px) {
  .price_container {
    font-size: 1.3em;
  }
}
@media (max-width: 640px) {
  .price_container {
    font-size: 1.2em;
  }
  .osteo_container,
  .massage_container {
    width: auto;
    /* margin-left: 0.5em; */
    margin: 0.5em;
  }
  table {
    width: auto;
  }
  .price_border_container {
    grid-template-rows: min-content min-content;
  }
}
@media (max-width: 590px) {
  section {
    min-height: auto;
    height: min-content;
  }
  .price_container {
    font-size: 1.1em;
    height: min-content;
  }
  .price_border_container {
    align-content: stretch;
  }
}
@media (max-width: 540px) {
  .price_container {
    font-size: 1em;
    border-radius: 0;
  }
  .price_container {
    margin: 0;
  }
}
@media (max-width: 485px) {
  .price_container {
    font-size: 0.9em;
  }
}
@media (max-width: 440px) {
  .price_container {
    font-size: 0.8em;
  }
}
@media (max-width: 390px) {
  .price_container {
    font-size: 0.7em;
  }
}
@media (max-width: 350px) {
  .price_container {
    font-size: 0.6em;
  }
}
