.about_container {
  height: 85vh;
  margin: 0.8em 1em 1em 1em;
  padding: 0.2em;
  background: linear-gradient(90deg, #49cddf 10%, #166094 100%);
  border-radius: 0 50px 0 50px;
  font-size: 1.45em;
  font-family: "Alegreya";
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.8);
}
.border_container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  background-color: white;
  border-radius: 0 47px 0 47px;
}
.photo_container {
  grid-row: 1/2;
  grid-column: 1/2;
  background-size: cover;
  background-clip: padding-box;
  border-radius: 0 0 0 49px;
}

h4 {
  display: none;
}
.text_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-row: 1/2 fr;
  grid-column: 2/3;
}
.body_text_container {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.body_text_container > p,
.final_text {
  text-align: justify;
  text-indent: 50px;
}
.body_text_container > ul {
  margin-left: 2em;
}
.body_text_containerr > ul > li {
  margin-left: 1em;
}
.final_text {
  padding: 1em;
  padding-top: 0;
}
@media (max-width: 1500px) {
  .about_container {
    font-size: 1.4em;
  }
}
@media (max-width: 1300px) {
  .about_container {
    font-size: 1.3em;
  }
}
@media (max-width: 1220px) {
  .about_container {
    font-size: 1.2em;
  }
}
@media (max-width: 1110px) {
  .about_container {
    font-size: 1.1em;
  }
}
@media (max-width: 1020px) {
  .about_container {
    font-size: 1em;
  }
}
@media (max-width: 930px) {
  .about_container {
    font-size: 0.85em;
  }
  .body_text_container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
/* -------------------------------------------- */
@media (max-width: 840px) {
  .border_container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    color: white;
    position: relative;
  }
  .photo_container {
    border-radius: 0 48px 0 48px;
    grid-column: 1/2;
    grid-row: 1/2;
  }
  h3 {
    display: none;
  }
  h4 {
    display: block;
  }
  .text_container {
    grid-row: 1/2;
    grid-column: 1/2;
    height: min-content;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    position: absolute;
    border-radius: 0 0 0 48px;
  }
  .body_text_container {
    flex-direction: row;
    justify-content: space-around;
    padding: 0 0.5em 0 0.5em;
  }
  .body_text_container > p {
    width: 50%;
  }
}
@media (max-width: 800px) {
  .about_container {
    font-size: 0.8em;
  }
}
@media (max-width: 710px) {
  .body_text_container {
    flex-direction: column;
    justify-content: space-around;
    padding: 1em;
  }
  .body_text_container > p {
    width: 100%;
  }
  .final_text {
    padding-left: 2em;
  }
}
@media (max-width: 500px) {
  .about_container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 0.85em;
  }
  .border_container {
    border-radius: 0;
  }
  .photo_container {
    border-radius: 0;
  }
  .text_container {
    border-radius: 0;
  }
  .final_text {
    padding-left: 1em;
  }
  .photo_container {
    background-position: -40px;
  }
}
@media (max-width: 400px) {
  .photo_container {
    background-position: -50px;
  }
}
@media (max-width: 380px) {
  .about_container {
    font-size: 0.7em;
  }
  .photo_container {
    background-position: -60px;
  }
}
@media (max-width: 350px) {
  .photo_container {
    background-position: -70px;
  }
}
@media (max-width: 340px) {
  .photo_container {
    background-position: -80px;
  }
}
@media (max-width: 300px) {
  .photo_container {
    background-position: -90px;
  }
}

@media (max-width: 280px) {
  .photo_container {
    background-position: -120px;
  }
}
/* @media (max-height: 640px) {
  .about_container {
    height: 640px;
  }
} */
